export default function ScrollUpArrow() {
  return (
    <svg viewBox="0 0 253 414" fill="none" xmlns="http://www.w3.org/2000/svg" className="ScrollUpArrow" onClick={() => {
      const el = document.querySelector(":root");
      window.scrollTo({top:el.offsetTop, behavior:"smooth"})
    }}>
      <g clip-path="url(#clip0_35_1111)">
      <path d="M194 101.501C210.5 186.5 166.728 225.806 139.797 219.285C127.946 216.419 132.85 202.505 141.01 205.806C146.351 207.969 146.059 214.752 144.45 220.548C139.576 238.096 99.4998 274.5 99.4998 274.5" stroke="#FF0000" stroke-width="2.5" stroke-miterlimit="10"/>
      <path d="M202.328 108.79L194.049 100.335L189 111.032" stroke="#FF0000" stroke-width="2.5" stroke-miterlimit="10"/>
      </g>
      <defs>
      <clipPath id="clip0_35_1111">
      <rect width="541.77" height="65.15" fill="white" transform="translate(252.106 22.9863) rotate(110.66)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
