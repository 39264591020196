import { useState, useEffect } from "react";
import useAppContext from "./AppContext";

export default function useWorkVisible(element) {
  const {setWorkCloudVisible} = useAppContext();

  useEffect(() => {
    const currentVisibility = new Map();

    const setVisibility = (entries) => {
      entries.forEach((element) => {
        currentVisibility.set(element.target, element.isIntersecting); // {section: is intersecting}
      });
    };

    const filterFirstVisible = () => {
      // get first key with a value of true
      const allVisibleSections = Array.from(currentVisibility)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);

      setWorkCloudVisible(allVisibleSections[0] ? true : false);
    };

    const observer = new IntersectionObserver( // takes in callback called each time section passes one of the threshold values [does not intersect, fully contained] and when it starts observing.
      (entries) => {
        setVisibility(entries);
        filterFirstVisible();
      },
      {
        root: document.querySelector("root"), // the scrollable part of the document
        rootMargin: "12% 0px 0px 0px", // only listens to the bottom half of .Subroot (which is equivelent to the bottom half of the viewport)
        threshold: [0.0, 1.0], // [no intersection, fully contained]
      }
    );
    
    if (element.current) {
      currentVisibility.set(element.current, false); // initialize map
      observer.observe(element.current); // start observing each section
    }
    
    
    

    return () => {
      observer.disconnect();
    };
  }, [element, setWorkCloudVisible]);
}