import { useState, useEffect } from "react"
function Champaigne() {
  return (
    <svg /*width="45" height="106"*/ viewBox="0 0 45 106" fill="none" xmlns="http://www.w3.org/2000/svg" className='Champaigne-SVG'>
      <path d="M31.0412 64.3594V104.599H40.8812H31.0412H21.2012" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M18.1113 23.2197H43.8813V51.4797C43.8813 58.5897 38.1113 64.3597 31.0013 64.3597C23.8913 64.3597 18.1213 58.5897 18.1213 51.4797V23.2197H18.1113Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M5.76953 23.9697V17.7197" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M5.76953 34.9797V28.7197" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M4.37 26.3496H0" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M7.16992 26.3496H11.5399" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M15.3613 11.2305V18.5105" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M11.8711 14.8701H18.8611" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M19.3203 3.93945V8.28945" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M17.2305 6.12012H21.4005" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M26.6502 51.1501C27.9812 51.1501 29.0602 50.0711 29.0602 48.7401C29.0602 47.4091 27.9812 46.3301 26.6502 46.3301C25.3192 46.3301 24.2402 47.4091 24.2402 48.7401C24.2402 50.0711 25.3192 51.1501 26.6502 51.1501Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M33.1705 43.7903C35.4404 43.7903 37.2805 41.9502 37.2805 39.6803C37.2805 37.4104 35.4404 35.5703 33.1705 35.5703C30.9006 35.5703 29.0605 37.4104 29.0605 39.6803C29.0605 41.9502 30.9006 43.7903 33.1705 43.7903Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M7.17046 6.88C8.79418 6.88 10.1105 5.56372 10.1105 3.94C10.1105 2.31628 8.79418 1 7.17046 1C5.54675 1 4.23047 2.31628 4.23047 3.94C4.23047 5.56372 5.54675 6.88 7.17046 6.88Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M23.5605 18.6599L24.6905 17.6299" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M23.5605 14.3906L24.6905 15.4206" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M28.3505 18.6599L27.2305 17.6299" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M28.3505 14.3906L27.2305 15.4206" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M35.4707 54.1706L36.3207 53.3906" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M35.4707 50.9297L36.3207 51.7097" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M39.1 54.1706L38.25 53.3906" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M39.1 50.9297L38.25 51.7097" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  )
}

function LeftSparkle() {

  return (
    <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg" className="LeftSparkle">
      <path d="M23.6473 11.7226L3 9.04883" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M27.646 20.7L22.9414 41.1532" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M21.8754 18.8222L1 31.2207" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  )
}

function RightSparkle() {
  
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg" className="RightSparkle">
      <path d="M6.1993 17.138L24.5957 7.38965" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M0.999071 14.8784L9.4707 1.20508" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M5.48493 22.1684L21.3223 24.9805" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  )
}

function ComeSayHi() {

  return (
    <div className="SayHiWrapperWrapper">
      <div className="SayHiWrapper">
        <LeftSparkle />
        <RightSparkle />
        {/* <span className="SayHiWrapper-Please">PLEASE</span> */}
        <span className="SayHiWrapper-FeelFree">Let's get in touch!</span>
        <span className="SayHiWrapper-ILove">I love making new friends!</span>
        <span className="SayHiWrapper-NewFriends"></span>
      </div>
    </div>


  )
}

function Martini() {

  return (
    <svg width="50" height="105" viewBox="0 0 50 105" fill="none" xmlns="http://www.w3.org/2000/svg" className="Martini-SVG">
      <path d="M2.50977 36.4297L24.9098 62.8597L47.3098 36.4297H2.50977Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M34.7503 103.099H24.9103H15.0703H24.9103V62.8594" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M40.5801 17.1602V29.2802" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M34.75 23.2197H46.4" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M36.2891 18.9297L44.8691 27.5097" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M36.4609 27.3396L44.7009 19.0996" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M21.8906 8.43016V0.910156" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M21.8906 21.6804V14.1504" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M17.6602 15.06L19.6502 13.25" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M17.6602 7.53027L19.6502 9.34027" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M26.1109 15.06L24.1309 13.25" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M26.1109 7.53027L24.1309 9.34027" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M28.8301 26.8799V31.2299" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M26.7402 29.0498H30.9202" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M13.0709 30.0304C14.6946 30.0304 16.0109 28.7141 16.0109 27.0904C16.0109 25.4667 14.6946 24.1504 13.0709 24.1504C11.4471 24.1504 10.1309 25.4667 10.1309 27.0904C10.1309 28.7141 11.4471 30.0304 13.0709 30.0304Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M15.1797 44.8298L16.3097 43.7998" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M15.1797 40.5605L16.3097 41.5905" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M19.9698 44.8298L18.8398 43.7998" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M19.9698 40.5605L18.8398 41.5905" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M30.9504 47.2406C31.9445 47.2406 32.7504 46.4347 32.7504 45.4406C32.7504 44.4465 31.9445 43.6406 30.9504 43.6406C29.9563 43.6406 29.1504 44.4465 29.1504 45.4406C29.1504 46.4347 29.9563 47.2406 30.9504 47.2406Z" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  )
}


export function Contact() {
  const [compactLayout, setCompactLayout] = useState(false);
  const breakpoint = 850;

  useEffect(() => {
   const handleResizeWindow = () => setCompactLayout(window.innerWidth <= breakpoint);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  
  return (
    <div className='ContactPage'>
        <ComeSayHi />
      <div className='ContactPage-Content'>

        <div className='ContactPage-Left'>

          <section className='Contact-Email'>
            <h2 className='EmailHeader'>EMAIL <Champaigne /></h2>
            <a href="mailto:jennacampbell815@gmail.com" className='EmailAddress'>JENNACAMPBELL815@GMAIL.COM</a>
            {/* <p className='EmailAddress'>JENNACAMPBELL815@GMAIL.COM</p> */}
          </section>
          
          <section className='Contact-Phone'>
            <h2 className='PhoneHeader'>PHONE</h2>
            <p className='PhoneNumber'>(913). 961. 3258</p>
          </section>
          
        </div>
        <div className='ContactPage-Right'>
          <section className='Contact-Linkedin'>
            <h2 className='LinkedinHeader'>LINKEDIN <Martini /></h2>
            
            <a href="https://linkedin.com/in/jenna-c-design" className="LinkedinUrl">LINKEDIN.COM/IN/JENNA-C-DESIGN</a>
            {/* <p className='LinkedinUrl'>LINKEDIN.COM/IN/JENNA-C-DESIGN</p> */}
          </section>
        
        </div>
      </div>
    </div>
  )
  
}
