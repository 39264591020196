
import { Picture } from "../Picture"

export default function Baseball() {
  return (
    <div className="WorkTopic BaseballPage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">Burlington Stone Wings Minor League Baseball Branding</p>
        
        <p className="TopicText">
        The goal of this project was to develop a cohesive brand identity for a fictional minor league baseball team, emphasizing the quirks and local ties that make minor league teams unique. The Burlington Stone Wings are deeply rooted in Vermont’s rich history of stonemasonry, gothic architecture, and folklore, drawing inspiration from the gargoyles that protect historic buildings, the craftsmanship of New England’s stone industry, and the eerie mystique of graveyards. The team name merges stone, symbolizing strength and power, with wings, representing agility and speed; a balance between hitting with force and flying around the bases. The mascot and branding system needed to capture both intimidation and minor league playfulness, making the Stone Wings a team that feels fierce yet fun, historic yet energetic.

        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        To bring this vision to life, I created a comprehensive branding system featuring a dynamic mascot, a set of interchangeable logos, a gothic-inspired monogram, and a supporting suite of pictorial symbols. The mascot, a jolly yet intimidating gargoyle, embodies both humor and strength, with chiseled stone muscles, cracks that mimic flexing veins, and sharp expressive features. The brand’s typography takes cues from New England’s historic architecture, using gothic letterforms and sharp, stone-carved elements, while the color palette: sky blue, stone gray, and a deep forest green, reflects both Vermont’s natural landscape and the durability of stone. The brand extends beyond logos into billboards, wheat-paste posters, stadium signage, and game-day promotions, creating a fully immersive identity. The stadium, aptly named "The Graveyard," reinforces the protective yet ominous nature of the brand, with water bottles, banners, and ticket designs featuring gravestone iconography and bold slogans. Every element of the identity is designed to be interchangeable, scalable, and seamlessly integrated, ensuring the Stone Wings brand is as strong and adaptable as the gargoyles it was inspired by.
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
        * BRANDING, ADVERTISING <br />
        * USING ADOBE ILLUSTRATOR, ADOBE PHOTOSHOP
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../baseball/page1.png" />
        <Picture img="../baseball/page2.png" />
        <Picture img="../baseball/2.png" />
        <Picture img="../baseball/3.png" />
        <Picture img="../baseball/4.png" />
        <Picture img="../baseball/5.png" />
        <Picture img="../baseball/6.png" />
        <Picture img="../baseball/7.png" />
        <Picture img="../baseball/8.png" />
        <Picture img="../baseball/9.png" />
        <Picture img="../baseball/10.png" />
        <Picture img="../baseball/11.png" />
      </div>
    </div>
  )
}