import { Picture } from "./Picture";
import Brainstorm from "./svgs/Brainstorm";
import Project4Spark from "./svgs/CornerSpark_Project4";
import HomepageArrowPrompt from "./svgs/HompageArrowPrompt";
import LadderProject1 from "./svgs/Ladder_Project1";
import ScrollUpArrow from "./svgs/ScrollUpArrow_BottomPage";
import ShootingStarProject3 from "./svgs/ShootingStarProject3"
import SparkleProject2 from "./svgs/SparkleProject2"

function Nuance() {
  return (
    <section className="Nuance">
      <div className="Nuance-Text">
        <p className="WorkDescription Nuance-Nuance">"NUANCE"</p>
        <p className="WorkDescription Nuance-Manifesto">MANIFESTO</p>
        <p className="WorkDescription Nuance-Publication">PUBLICATION</p>
        <p className="WorkDescription Nuance-Poster">& POSTER</p>
        <LadderProject1 />
      </div>
      <Picture img={"nuance/Cover_1.png"} route="nuance" />
    </section>
  )
}

function CreativeFlux() {
  return (
    <section className="CreativeFlux">
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <SparkleProject2 />
        <Picture img={"creative_flux/MOCK_POSTER.png"} route="flux" />
      </div>
      <div className="CreativeFlux-Text">
        <p className="WorkDescription CreativeFlux-1">"Creative Flux"</p>
        <p className="WorkDescription CreativeFlux-2">Project</p>
        <p className="WorkDescription CreativeFlux-3">Analysis</p>
        <p className="WorkDescription CreativeFlux-4">Poster</p>
      </div>
      
    </section>
  )
}

function Pump() {
  return (
    <section className="Pump">
      <Picture img={"pump/Cover_pump.png"} route="pump" />
      <div className="Pump-Text">
        <p className="WorkDescription Pump-1">
          "PUMP" 
          <span style={{"paddingLeft": '1.5rem'}}></span>
          BY REEBOK
        </p>
        <p className="WorkDescription Pump-2">ENERGY DRINK</p>
        <p className="WorkDescription Pump-3">NEW LINE</p>
        <p className="WorkDescription Pump-4">BRANDING</p>
        <ShootingStarProject3 />
      </div>
    </section>
  )
}

function Flipturn() {
  return (
    <section className="Flipturn">
      <Picture img={"flipturn/Flipturn_Portfolio 2 1.jpg"} route="flipturn" />
      <div className="Flipturn-Text">
        <p className="WorkDescription Flipturn-1">
          "Flipturn"
        </p>
        <p className="WorkDescription Flipturn-2">band</p>
        <p className="WorkDescription Flipturn-3">concert</p>
        <p className="WorkDescription Flipturn-4">poster</p>
      </div>
    </section>
  )
}

function Exactly() {
  return (
    <section className="Exactly">
      <Picture img={"exactly/package COVER.jpg"} route="exactly" />
      <div className="Exactly-Text">
        <p className="WorkDescription Exactly-1">"EXACTLY"</p>
        <p className="WorkDescription Exactly-2">Measuring</p>
        <p className="WorkDescription Exactly-3">Spoon</p>
        <p className="WorkDescription Exactly-4">Packaging</p>
        
      </div>
    </section>
  )
}

function Vote() {
  return (
    <section className="Vote">
      {/* <div style={{display: 'flex'}}> */}

        <Picture img={"vote/cover.png"} route="vote" />
        
        <div className="Vote-Text">
          <p className="WorkDescription Vote-1">"Go Vote"</p>
          <p className="WorkDescription Vote-2">energetic</p>
          <p className="WorkDescription Vote-3">advocacy</p>
          <p className="WorkDescription Vote-4">campaign</p>
          
        </div>
      {/* </div> */}
      
    </section>
  )
}


function Strange() {
  return (
    <section className="Strange">
      <div style={{display: 'flex'}}>

        <Picture img={"strange/LyricCover.png"} route="strange" />
        <Project4Spark />
        <div className="Strange-Text">
          <p className="WorkDescription Strange-1">STRANGE TRAILS</p>
          <p className="WorkDescription Strange-2">LORD HURON</p>
          <p className="WorkDescription Strange-3">ALBUM LYRICS</p>
          <p className="WorkDescription Strange-4">PUBLICATION</p>
          
        </div>
      </div>
      <Project4Spark />
      
    </section>
  )
}

export function Work() {

  return (
    <div className="WorkPage">
      <section className="JNCMain">
      <div className="JNCMain-Filler"></div>

        <div className="JNCMain-Text">
          <h1 className="JNCMain-Title">
          <Brainstorm />
            <span>JENNA</span>
            <br/>
            CAMPBELL
          </h1>
          <p className="JNCMain-Subtitle">
            (GRAPHIC DESIGNER, MEANING SEAKER, BEAUTY CONNOISSEUR, & SENSITIVE BEING)
          </p>
        </div>
        <HomepageArrowPrompt />
      </section>
      <Nuance />
      <CreativeFlux />
      <br />
      <br />
      <Strange />
      <br />
      <Pump />
      <br />
      <Flipturn />
      <br />
      <br />
      <Vote />
      <ScrollUpArrow />
    </div>
  )
}