
import { Picture } from "../Picture"

export default function Flipturn() {

  return (
    <div className="WorkTopic FlipturnPage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">"Flipturn" band concert Poster</p>
        
        <p className="TopicText">
          I was tasked with creating a band poster for an artist of my choice using collage and type. 
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
          I used archival, free use imagery and spent time cutting and pasting them while I listened to the album for their 2022 Shadowglow concert to study the themes present throughout the whole album. Once I finished the cutting and pasting, I scanned in the poster as a whole to get my black and white collage poster. Then, using that poster I printed a bright red version on the risograph as this color was prominent in this album and concert's design concept. Once I had digitized this version, I posted it to instagram and tagged the band. A couple of their members liked the post. 
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
          * COLLAGE
          <br />
          * USING ADOBE PHOTOSHOP
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../flipturn/Flipturn_page1.jpg" />
        <Picture img="../flipturn/Flipturn_page2.png" />
        <Picture img="../flipturn/Flipturn_page3.jpg" />
      </div>
    </div>
  )
}