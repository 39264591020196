import { NavLink } from "react-router-dom";
import useAppContext from "../utils/AppContext";

function Logo() {
  const { setPage } = useAppContext();
  
  const _onClick = () => {
    setPage(() => {
      return 0;
    })
  }

  return (
    <div>
      <NavLink 
        to={`/`}
        onClick={_onClick}
      >
      
        <svg viewBox="0 0 144 80" fill="none" xmlns="http://www.w3.org/2000/svg" className="JNCLogo">
          <g clipPath="url(#clip0_35_11)">
          <path d="M141.977 56.0646C137.502 65.2881 127.257 72.1979 117.328 72.1979C114.514 72.1979 111.21 71.2063 108.554 68.5778C105.076 65.115 103.099 59.0237 103.099 37.6176C103.099 16.2115 105.581 7.97965 108.886 4.6743C109.882 3.68269 110.894 2.84848 111.937 2.26611C112.57 1.91984 112.933 1.7467 112.933 1.13285C112.933 0.660657 112.538 0.267162 112.064 0.298642C91.5261 1.38469 75.1309 16.0541 75.1309 37.6176C75.1309 59.1811 91.0044 74.9996 111.858 74.9996C128.902 74.9996 138.325 66.4371 143.447 56.7257C143.937 55.9072 142.293 55.4036 141.961 56.0646H141.977Z" fill="#FF0000"/>
          <path d="M74.0715 0H29.1229C28.6328 0 28.3008 0.157398 28.3008 0.818468C28.3008 1.47954 28.6328 1.63694 29.1229 1.63694H39.2099V56.4113C39.2099 69.192 36.7751 72.6233 33.1229 73.2529C32.6486 73.3316 32.285 73.7408 32.285 74.2287C32.285 74.6537 32.6486 75 33.0755 74.9842C50.6407 74.2917 65.6605 61.4323 65.6605 40.766V1.65268H74.0399C74.53 1.65268 74.862 1.49528 74.862 0.834208C74.862 0.173137 74.53 0.0157398 74.0399 0.0157398L74.0715 0Z" fill="#FF0000"/>
          <path d="M129.866 3.9668H126.071V35.9028H129.866V3.9668Z" fill="#FF0000"/>
          <path d="M144 18.0381H111.921V21.8156H144V18.0381Z" fill="#FF0000"/>
          <path d="M117.966 7.30348L115.283 9.97461L137.967 32.5568L140.65 29.8856L117.966 7.30348Z" fill="#FF0000"/>
          <path d="M137.955 7.30358L115.271 29.8857L117.955 32.5569L140.638 9.97471L137.955 7.30358Z" fill="#FF0000"/>
          <path d="M17.9449 39.3965H14.1504V71.3325H17.9449V39.3965Z" fill="#FF0000"/>
          <path d="M32.0791 53.4834H0V57.2609H32.0791V53.4834Z" fill="#FF0000"/>
          <path d="M6.04637 42.739L3.36328 45.4102L26.0466 67.9923L28.7297 65.3212L6.04637 42.739Z" fill="#FF0000"/>
          <path d="M26.0466 42.7499L3.36328 65.332L6.04638 68.0032L28.7297 45.421L26.0466 42.7499Z" fill="#FF0000"/>
          </g>
          <defs>
          <clipPath id="clip0_35_11">
          <rect width="144" height="75" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </NavLink>
    </div>

  )
}

function Button({text, route, index}) {
  const { setPage } = useAppContext();
  
  const _onClick = () => {
    setPage(() => {
      return index;
    })
  }
  const setClassName = ({ isActive }) => {
    
    if (isActive) {
      return 'CloudButton CloudButtonSelected';
    } else {
      return 'CloudButton CloudButtonNotSelected';
    }
  };

  return (
    <>
      <NavLink
        to={`/${route}`}
        className={setClassName}
        onClick={_onClick}
      >

        <svg /*width="152" height="83"*/ viewBox="0 0 152 87" fill="none" xmlns="http://www.w3.org/2000/svg" className="JNCCloud">
          <path d="M123.609 30.5032C161.361 36.5938 149.098 76.7057 149.098 76.7057C148.198 79.3166 146.915 81.3231 146.387 82H5.26353C1.8527 78.0363 1.00001 71.1899 1 66.6857C2.36433 36.4175 26.3042 36.425 38.3168 38.861C38.3168 38.861 28.1234 4.47746 65.6599 1.19071C103.196 -2.09605 98.1187 38.1902 98.1187 38.1902C106.624 29.1214 117.046 29.4434 123.609 30.5032Z" stroke="#FF0000" strokeWidth="2.5" strokeMiterlimit="10" />
          <text  fontSize="20" dominantBaseline="middle" x="50%" y="70%">{text}</text>
        </svg>
      </NavLink>
      {/* <button onClick={myAction}>
      </button> */}
      
    {/* // </div> */}
    </>
  )
}

export function NavBar({ selected, setSelected }) {
  const { workCloudVisible } = useAppContext();
  
  return (
    <div className="NavBarWrapper">
      <div className='NavBar'>
        <Logo/>
        {workCloudVisible && selected===0 ? <></> : <Button text='WORK' route='' index={0} /> }
        <Button text='ABOUT' route='about' index={1} />
        <Button text='CONTACT' route='contact' index={2} />
      </div>
    </div>
  )
}