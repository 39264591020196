export default function SparkleProject2() {
  return (
    <svg width="82" height="78" viewBox="0 0 82 78" fill="none" xmlns="http://www.w3.org/2000/svg" className="SparkleProject2">
      <path d="M27.2305 11.47V0" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M13.3203 9.08984L17.2803 13.4298" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M41.1397 9.08984L37.1797 13.4298" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M0.740234 23.4004H15.3002" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M39.1602 23.4004H53.7202" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M13.3203 37.6994L17.2803 33.3594" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M41.1397 37.6994L37.1797 33.3594" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M27.2305 35.3203V46.7903" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M70.9502 44.3203L77.0102 57.9703" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M71.4004 57.8392L76.5504 44.4492" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M67.4199 54.0605L80.5299 48.2305" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M67.0098 48.4609L80.9498 53.8209" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M14.25 64.6895V77.4295" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M9.74023 66.5508L18.7502 75.5708" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M9.91992 75.3905L18.5799 66.7305" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
      <path d="M8.12012 71.0605H20.3701" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10" className="Sparkle-Star"/>
    </svg>
  )
}