// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.NavBar {
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  background-color: #d1c8c1;
  width: calc(100% - var(--hBodyPadding)*2);
  margin-left: var(--hBodyPadding); /* 1795 */
  height: var(--navBarHeight);
  border: 0;
  border-bottom: 2px solid #ff0000;
  margin-bottom: 5px;
}

.JNCLogo {
  /* width: 120px; */
  height: 3.5rem;
  margin-left: 20px;
  fill: #ff0000;
}

.NavBar .CloudButton:first-of-type {
  margin-left: auto;
}

.NavBar .CloudButton {
  padding-right: 1rem;
}

.JNCCloud {
  width: 7rem;
  /* height: 3.5rem; */
}

.CloudButton button {
  background-color: transparent;
  border: 0;
}

.CloudButton svg text {
  text-anchor: middle;
  text-align: center;
  font-family: "archivo", sans-serif;
  font-size: 1.2rem;
  font-weight:900;
}

.CloudButtonNotSelected svg text{
  fill: #ff0000;
  font-weight:normal;
}

.CloudButtonNotSelected svg path {
  fill: transparent;
}

.CloudButtonSelected svg path,
.CloudButton:hover svg path {
  fill: #ff0000;
}

.CloudButtonSelected svg text,
.CloudButton:hover svg text {
  fill: #d1c8c1;
  font-weight:bolder;
}




`, "",{"version":3,"sources":["webpack://./src/styles/NavBar.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,yCAAyC;EACzC,gCAAgC,EAAE,SAAS;EAC3C,2BAA2B;EAC3B,SAAS;EACT,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,6BAA6B;EAC7B,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,kCAAkC;EAClC,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":["\n.NavBar {\n  position: fixed;\n  z-index: 9999;\n  display: flex;\n  align-items: center;\n  background-color: #d1c8c1;\n  width: calc(100% - var(--hBodyPadding)*2);\n  margin-left: var(--hBodyPadding); /* 1795 */\n  height: var(--navBarHeight);\n  border: 0;\n  border-bottom: 2px solid #ff0000;\n  margin-bottom: 5px;\n}\n\n.JNCLogo {\n  /* width: 120px; */\n  height: 3.5rem;\n  margin-left: 20px;\n  fill: #ff0000;\n}\n\n.NavBar .CloudButton:first-of-type {\n  margin-left: auto;\n}\n\n.NavBar .CloudButton {\n  padding-right: 1rem;\n}\n\n.JNCCloud {\n  width: 7rem;\n  /* height: 3.5rem; */\n}\n\n.CloudButton button {\n  background-color: transparent;\n  border: 0;\n}\n\n.CloudButton svg text {\n  text-anchor: middle;\n  text-align: center;\n  font-family: \"archivo\", sans-serif;\n  font-size: 1.2rem;\n  font-weight:900;\n}\n\n.CloudButtonNotSelected svg text{\n  fill: #ff0000;\n  font-weight:normal;\n}\n\n.CloudButtonNotSelected svg path {\n  fill: transparent;\n}\n\n.CloudButtonSelected svg path,\n.CloudButton:hover svg path {\n  fill: #ff0000;\n}\n\n.CloudButtonSelected svg text,\n.CloudButton:hover svg text {\n  fill: #d1c8c1;\n  font-weight:bolder;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
