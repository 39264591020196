import { useState, useEffect } from "react";
import { Picture } from "./Picture";
import Brainstorm from "./svgs/Brainstorm";
import Project4Spark from "./svgs/CornerSpark_Project4";
import HomepageArrowPrompt from "./svgs/HompageArrowPrompt";
import LadderProject1 from "./svgs/Ladder_Project1";
import ScrollUpArrow from "./svgs/ScrollUpArrow_BottomPage";
import ShootingStarProject3 from "./svgs/ShootingStarProject3"
import SparkleProject2 from "./svgs/SparkleProject2"
import StairsEducationAbout from "./svgs/Stairs_AboutPageEducation";
import SparkleExperienceAbout from "./svgs/CornerSparkle_AboutPageExperience";

function Pump({ compactLayout }) {
  return (
    <section className="Pump">
      <Picture img={"pump/Cover_pump.png"} route="pump" />
      <div className="Pump-Text">
        <p className="WorkDescription Pump-1">"PUMP" BY REEBOK</p>
        <p className="WorkDescription Pump-2">ENERGY DRINK</p>
        <p className="WorkDescription Pump-3">NEW LINE</p>
        <p className="WorkDescription Pump-4">BRANDING</p>
      </div>
        
    </section>
  )
}


function Nuance({ compactLayout }) {

    
  
  return (
    <section className="Nuance">
      <div className="Nuance-Text">
        <p className="WorkDescription Nuance-Nuance">"NUANCE"</p>
        <p className="WorkDescription Nuance-Manifesto">MANIFESTO</p>
        <p className="WorkDescription Nuance-Publication">PUBLICATION</p>
        <p className="WorkDescription Nuance-Poster">& POSTER</p>
      </div>
      <Picture img={"nuance/Cover_1.png"} route="nuance" />

      
    </section>
  )
}

function CreativeFlux({ compactLayout }) {
    
  return (
    <section className="CreativeFlux">
      <Picture img={"creative_flux/MOCK_POSTER.png"} route="flux" />
      <div className="CreativeFlux-Text">
        <p className="WorkDescription CreativeFlux-1">"Creative Flux"</p>
        <p className="WorkDescription CreativeFlux-2">Project</p>
        <p className="WorkDescription CreativeFlux-3">Analysis</p>
        <p className="WorkDescription CreativeFlux-4">Poster</p>
      </div>
      
    </section>
  )
}


function Flipturn({ compactLayout }) {
  return (
    <section className="Flipturn">
      <Picture img={"flipturn/Flipturn_Portfolio 2 1.jpg"} route="flipturn" />
      <div className="Flipturn-Text">
        <p className="WorkDescription Flipturn-1">
          "Flipturn"
        </p>
        <p className="WorkDescription Flipturn-2">band</p>
        <p className="WorkDescription Flipturn-3">concert</p>
        <p className="WorkDescription Flipturn-4">poster</p>
      </div>
    </section>
  )
}

function Vote({ compactLayout }) {
  return (
    <section className="Vote">
        <Picture img={"vote/cover.png"} route="vote" />
        
        <div className="Vote-Text">
          <p className="WorkDescription Vote-1">"Go Vote"</p>
          <p className="WorkDescription Vote-2">energetic</p>
          <p className="WorkDescription Vote-3">advocacy</p>
          <p className="WorkDescription Vote-4">campaign</p>
          
        </div>
    </section>
  )
}


function Strange({ compactLayout }) {
  return (
    <section className="Strange">
      <div style={{display: 'flex'}}>

        <Picture img={"strange/LyricCover.png"} route="strange" />
        <Project4Spark />
        <div className="Strange-Text">
          <p className="WorkDescription Strange-1">STRANGE TRAILS</p>
          <p className="WorkDescription Strange-2">LORD HURON</p>
          <p className="WorkDescription Strange-3">ALBUM LYRICS</p>
          <p className="WorkDescription Strange-4">PUBLICATION</p>
          
        </div>
      </div>
      <Project4Spark />
      
    </section>
  )
}

function Gusto({ compactLayout }) {
  return (
    <section className="Gustos">
      <div className="Gustos-Text">
        <p className="WorkDescription Gustos-1">“Gusto’s”</p>
        <p className="WorkDescription Gustos-2">Italian</p>
        <p className="WorkDescription Gustos-3">Fast-Food</p>
        <p className="WorkDescription Gustos-4">Restaurant</p>
      </div>
      <Picture img={"gustos/Logo Lockup.jpg"} route="gustos" />
    </section>
  )
}

function Baseball() {
  return (
    <section className="Baseball">
      {/* <div className="Baseball-Text"> */}
        {/* <p className="WorkDescription Baseball-1">“Gusto’s”</p> */}
        {/* <p className="WorkDescription Gustos-2">Italian</p> */}
        {/* <p className="WorkDescription Gustos-3">Fast-Food</p> */}
        {/* <p className="WorkDescription Gustos-4">Restaurant</p> */}
      {/* </div> */}
      <Picture img={"baseball/cover.png"} route="baseball" />
    </section>
  )
}

function Bears() {
  return (
    <section className="Bears">
      {/* <div className="Baseball-Text"> */}
        {/* <p className="WorkDescription Baseball-1">“Gusto’s”</p> */}
        {/* <p className="WorkDescription Gustos-2">Italian</p> */}
        {/* <p className="WorkDescription Gustos-3">Fast-Food</p> */}
        {/* <p className="WorkDescription Gustos-4">Restaurant</p> */}
      {/* </div> */}
      <Picture img={"bears/cover.png"} route="bears" />
    </section>
  )
}

export function Work() {
  const [compactLayout, setCompactLayout] = useState(false);
  const breakpoint = 880;

  useEffect(() => {
   const handleResizeWindow = () => setCompactLayout(window.innerWidth <= breakpoint);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);


  return (
    <div className="WorkPage">
      <section className="JNCMain">
          <Brainstorm />
        <div className="JNCMain-Text">
          <h1 className="JNCMain-Title">
            <span>JENNA</span>
            <br/>
            CAMPBELL
          </h1>
          <p className="JNCMain-Subtitle">
            (GRAPHIC DESIGNER, MEANING SEAKER, BEAUTY CONNOISSEUR, & SENSITIVE BEING)
          </p>
        </div>
        <HomepageArrowPrompt />
      </section>
      <div className='WorkSizer'>
        <section className="WorkContent">
          <Pump compactLayout={compactLayout} />
          <SparkleProject2 />
          <LadderProject1 />
          <ShootingStarProject3 />
          <StairsEducationAbout />
          <SparkleExperienceAbout />
          <Nuance compactLayout={compactLayout} />
          <CreativeFlux compactLayout={compactLayout} />
          <Strange compactLayout={compactLayout} />
          <Flipturn compactLayout={compactLayout} />
          <Vote compactLayout={compactLayout} />
          <Gusto compactLayout={compactLayout} />
          <Baseball />
          <Bears />
        </section>
      </div>
      <ScrollUpArrow />
    </div>
  )
}