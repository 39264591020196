
import { Picture } from "../Picture"

export default function Exactly() {
  return (
    <div className="WorkTopic ExactlyPage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">“Exactly” Measuring Spoon Packaging</p>
        
        <p className="TopicText">
        I was tasked with selecting a household object and redesigning its brand, including a logo and its system. The next step was to create a package design that was both effective and eco-friendly, keeping it minimal while displaying all necessary product information.
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        I chose measuring spoons because the existing package designs I saw in stores were busy, not eco-friendly, and generally unappealing. I designed my packaging to be minimal, leveraging the spoons' connecting ring as a built-in solution for hanging the product on display, eliminating the need for additional plastic rings or hooks. After sketching out ideas, I narrowed down the branding to four concepts before finalizing the logotype and choosing a direction. I then created a formal dieline, laser cut the design into chipboard material, and folded it into a complete package.
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
        * BRANDING, PACKAGING<br />
        * USING ADOBE ILLUSTRATOR
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../exactly/package PG 1.jpg" />
        <Picture img="../exactly/package PG 2.jpg" />
        <Picture img="../exactly/package PG 3.jpg" />
        <Picture img="../exactly/package PG 4.jpg" />
        <Picture img="../exactly/package PG 5.jpg" />
        <Picture img="../exactly/package PG 6.jpg" />
        <Picture img="../exactly/package PG 7.jpg" />
        <Picture img="../exactly/package PG 8.jpg" />
      </div>
    </div>
  )
}