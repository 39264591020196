// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.JNCCaption {
  text-align: left; 
  align-content: flex-start;
  font-family: "hepta-slab", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* color: red; */
  font-size: 1rem;
  /* font-weight: bold; */
  color: #111;
}

.JNCType {
  font-size: larger;
  /* font-weight: bolder; */

  font-family: "archivo", sans-serif;
  font-weight: 400;
  font-style: normal;

  color: #ff0000;
  
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  align-self:flex-start;
}

.tmp {
  font-family: "hepta-slab", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.tmp2 {
  font-family: "archivo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
}

.NittiGrotesk {
  font-family: "nitti-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ContactType {

  font-family: "hepta-slab", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* color: red; */
  font-size: 1.5rem;
  /* font-weight: bold; */
  color: #111;
}`, "",{"version":3,"sources":["webpack://./src/styles/Text.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,qCAAqC;EACrC,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;;EAEzB,kCAAkC;EAClC,gBAAgB;EAChB,kBAAkB;;EAElB,cAAc;;EAEd,qBAAqB;EACrB,sBAAsB;EACtB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,wCAAwC;EACxC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,qCAAqC;EACrC,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,WAAW;AACb","sourcesContent":["\n.JNCCaption {\n  text-align: left; \n  align-content: flex-start;\n  font-family: \"hepta-slab\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  /* color: red; */\n  font-size: 1rem;\n  /* font-weight: bold; */\n  color: #111;\n}\n\n.JNCType {\n  font-size: larger;\n  /* font-weight: bolder; */\n\n  font-family: \"archivo\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n\n  color: #ff0000;\n  \n  display: inline-block;\n  vertical-align: middle;\n  margin: 0;\n  align-self:flex-start;\n}\n\n.tmp {\n  font-family: \"hepta-slab\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.tmp2 {\n  font-family: \"archivo\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  font-size: 3rem;\n}\n\n.NittiGrotesk {\n  font-family: \"nitti-grotesk\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.ContactType {\n\n  font-family: \"hepta-slab\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  /* color: red; */\n  font-size: 1.5rem;\n  /* font-weight: bold; */\n  color: #111;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
