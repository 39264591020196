
import { Picture } from "../Picture"

export default function Gustos() {
  return (
    <div className="WorkTopic GustosPage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">“Gusto’s” Italian Fast-Food Restaurant </p>
        
        <p className="TopicText">
        For this project, I was asked to design the identity for a new fast-food restaurant. I drew three cards containing specific descriptor words that would determine the brand’s direction. This was the only constraint, allowing us full creative liberty to explore the themes we were given. The final deliverable would include not only the brand system but also a book of brand guidelines detailing the brand voice and visual identity.
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        I chose to create an Italian fast-food restaurant named “Gusto's,” focusing on blending the industrial, farm-to-table, and retro themes I had drawn into a cohesive brand identity. I spent considerable time sketching and developing a distinctive logotype and a series of related icons that were used for secondary logos and patterns, central to the brand’s playful yet cohesive visual language. To bring the brand to life, I designed custom mockups for collateral such as way-finding signs, menus, containers, and more. Later, I added motion to the primary logotype to give it new life. I also developed comprehensive brand guidelines, detailing rules such as permitted colorways and appropriate advertising language to use, to properly reflect the brand’s voice.
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TOPICTEXT">
        * BRANDING, MOTION <br />
        * USING ADOBE PHOTOSHOP, ADOBE ILLUSTRATOR, ADOBE INDESIGN, ADOBE AFTER EFFECTS, FIGMA
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../gustos/gustos_animation.gif" />
        <Picture img="../gustos/Logo Lockup.jpg" />
        <Picture img="../gustos/Gusto's_portfolioPG2.png" />
        <Picture img="../gustos/Banner Lockups.jpg" />
        <Picture img="../gustos/Gusto's_portfolioPG4 1.png" />
        <Picture img="../gustos/Logo Footer.jpg" />
      </div>
    </div>
  )
}