
import { Picture } from "./Picture"
import CornerSparkExperienceAbout from "./svgs/CornerSpark_AboutPage_Experience"
import Project4Spark from "./svgs/CornerSpark_Project4"
import SparkleExperienceAbout from "./svgs/CornerSparkle_AboutPageExperience"
import StairsEducationAbout from "./svgs/Stairs_AboutPageEducation"

export function About() {

  return (
    <div className="AboutPage">
      <section className="About">
        <h2 className="About-Header">
          JENNA 
          <br />
          CAMPBELL
        </h2>
        <p className="About-Text">
          Hello there! Nice to meet you! I’m Jenna, a senior at the University of Kansas pursuing a BFA in Visual Communications. I am passionate about branding, packaging, and publication design. I’m driven by the power of color, typography, and telling meaningful stories through creative solutions and cohesive design. I love making new friends and would be thrilled to connect—whether to chat about design or problem-solve for an exciting new project!
        </p>
      </section>
      <div className="About-Middle">
        <Picture img="../HeadshotEdited.png" />

        <section className="Education">
          <div className="StairsPWrapper">
            <StairsEducationAbout />
            <h2 className="Education-Header">EDUCATION</h2>

            <p className="Education-KU">
              UNIVERSITY OF KANSAS 
              <br/>
              *Graduating May 2025 
              <br />
              *GPA: 3.92 
              <br />
              *University Honor Roll: all 7 semesters: August 2021 - December 2024 
              <br />
              *Featured Work for KU School of Design Accredidation: May 2023 
              <br />
            </p>
            <p className="Education-RMNP">
              DESIGN OUTSIDE STUDIO, ROCKY MOUNTAIN NATIONAL PARK, CO 
              <br />
              In July 2024, I travelled with peers to the Rocky Mountains of Colorado, exploring and documenting diverse flora and landscapes. I collaborated with community organizations on graphics promoting environmental awareness and visitor education.
            </p>
          </div>
        </section>
      </div>
      <section className="Experience">
        <h2 className="Experience-Header">
          <Project4Spark />
          EXPERIENCE
          <SparkleExperienceAbout />
        </h2>
        <p className="Experience-KU">
          UNIVERSITY OF KANSAS, ACADEMIC SUCCESS INITIATIVES <br />
          May 2024 – April 2025 <br />
          As a Graphic Design Intern, I developed marketing campaigns and designed web and print graphics for events, social media, and promotions. Working within brand guidelines strengthened my ability to balance creativity with consistency.
        </p>
        <p className="Experience-Photography">
          JENNA CAMPBELL PHOTOGRAPHY <br />
          May 2019 – August 2023 <br />
          I photographed and edited senior portraits, LinkedIn headshots, and competition art, gaining hands-on experience in capturing meaningful moments and delivering polished visuals.
        </p>
      </section>
    </div>
  )
}