import './App.css';

import { useState } from 'react';
import { NavBar } from './components/NavBar'

import { AppContext } from './utils/AppContext';
import { Outlet } from 'react-router-dom';

function Footer({ selected }) {

  return (
    <footer className={selected === 0? 'footer work-footer' : 'footer'}>
      ©2024 (Jenna Campbell) All Rights Reserved.
    </footer>
  )
}

function App() {
  const [selected, setSelected] = useState(0);
  const [workCloudVisible, setWorkCloudVisible] = useState(false);

  const context = {
    setPage: setSelected,
    currentPage: selected,
    workCloudVisible: workCloudVisible,
    setWorkCloudVisible: setWorkCloudVisible,
  };

  return (
    <>
    
      <AppContext.Provider value={context}>

      <div className="App">
        <NavBar selected={selected} setSelected={setSelected}/>
        
        <div className="App-Page">
          <Outlet />
        </div>
          <Footer selected={selected}/>

      </div>
      </AppContext.Provider>
    </>
  );
}

export default App;
