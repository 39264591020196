import { NavLink } from "react-router-dom";

function PictureWrapper({ route, child }) {
  let wrapper = <>{child}</>
  if (route) {
    wrapper = <NavLink to={`/work/${route}`}>{child}</NavLink>
  }
  return wrapper
}


export function Picture({ img, route }) {
  let content = <img className="ImgWrapper-Img" src={img} alt="" />
  if (img === "") {
    content = <div className="ImgWrapper-Fake"></div>
  } else if (img.includes(".pdf")) {
    content = <embed 
      src={img}
      className="ImgWrapper-Img"
      width="500" 
      height="375" 
      type="application/pdf"
    ></embed>
  }

  return (
    <>
      <PictureWrapper route={route} child={
        <div className="ImgWrapper">
          {content}
        </div>
      }/>
    </>
  )
}