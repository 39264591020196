
import { Picture } from "../Picture"

export default function Flux() {

  return (
    <div className="WorkTopic FluxPage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">“creative flux” project analysis Poster</p>
        {/* <p className="TopicSubtitle">Data visualization, mapping / autumn 2024</p>
        <p className="TopicHeading">PROGRAMS USED</p>
        <p className="TopicText">Adobe Illustrator</p> */}
        {/* <p className="TopicHeading">CHALLENGE</p> */}
        <p className="TopicText">
          I set out to create a representation mapping each one of my past design projects, visually abstracting the intersection of analyses with the fluctuations of my creative spark—how inspired, motivated, and productive I felt; and how fluid or stuck my creative process was—over my college design career. The objective was to categorize each project based on three factors that shape my design approach: the 8 Categories of Design Competencies (as defined by AIGA and NASAD), the 6 Degrees of Learning Taxonomy (developed by Benjamin Bloom), and Levels of Creative Spark, defined through my personal experience. My challenge was to translate these frameworks into a cohesive visual system/chart illustrating the diversity of skills, learning, and creative spark across my past projects.
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
          To achieve this, I developed a poster using typography, shape, and color to visually distinguish each project according to its assigned categories. Bloom’s Taxonomy levels are represented through varied, distinctive typefaces, each reflecting a different level of cognitive learning. Essential Competencies are symbolized with abstract, futuristic/glittery shapes that convey the nature of each competency. Each project is assigned a gradient color from a five-level creative spark scale to express the strength of creative energy—from “Flickering” to “Electrifying.” Together, these elements form a vibrant interwoven chart that communicates the fluctuation of my design strengths and growth. This poster serves as both a reflection of my design journey and a tool for understanding the interplay of competencies, learning, and inspiration in my work.
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
          * DATA VISUALIZATION <br/>
          * USING ADOBE ILLUSTRATOR
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../creative_flux/Last_Poster_iterations.jpg" />
        <Picture img="../creative_flux/MOCK_POSTER.png" />
        <Picture img="../creative_flux/key.png" />
        {/* <Picture img="../creative_flux/competencies.jpg" /> */}
        {/* <Picture img="../creative_flux/spark_levels.jpg" /> */}
        {/* <Picture img="../creative_flux/taxonomy.jpg" /> */}
      </div>

    </div>
  )
}