
export default function SparkleExperienceAbout() {
  return (
    <svg /*width="85" height="117"*/ viewBox="0 0 85 117" fill="none" xmlns="http://www.w3.org/2000/svg" className="SparkleExperienceAbout">
      <g clipPath="url(#clip0_33_191)">
      <path d="M52.1094 69.7002V43.1602" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M52.1094 116.42V89.8799" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M37.1895 93.0804L44.2095 86.6904" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M38.5695 79.79H20.0195" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M37.1895 66.4902L44.2095 72.8902" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M67.0198 93.0804L60.0098 86.6904" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M65.6504 79.79H84.1904" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M67.0198 66.4902L60.0098 72.8902" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      </g>
      <defs>
      <clipPath id="clip0_33_191">
      <rect width="84.19" height="116.42" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}