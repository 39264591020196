export default function HomepageArrowPrompt() {
  return (
    <svg /* width="456" height="401" */ viewBox="0 0 300 300" /*"0 0 456 401"*/ fill="none" xmlns="http://www.w3.org/2000/svg" className="HomepageArrowPrompt">
      <g clip-path="url(#clip0_35_25)">
        <path d="M7.31445 50.0029C129.003 45.5306 96.4979 174.799 181.074 170.323C187.087 170.005 194.887 166.779 193.179 161.005C192.045 157.171 187.447 155.096 183.731 157.895C181.666 159.45 181.171 162.673 182.42 166.182C186.578 177.86 200.683 183.056 211.918 188.295C237.153 200.063 271.257 205.281 268.687 259.544" stroke="#FF0000" stroke-width="2.5" stroke-miterlimit="10" className="arrowBase"/>
        <path d="M276.5 252.215L268.5 261L260.912 252.215" stroke="#FF0000" stroke-width="2.5" stroke-miterlimit="10" className="arrowTip"/>
      </g>
      <defs>
      <clipPath id="clip0_35_25">
        <rect width="541.77" height="65.15" fill="white" transform="matrix(0.762529 0.646954 0.646954 -0.762529 0.322266 50.3564)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
