
import { Picture } from "../Picture"

export default function Strange() {
  return (
    <div className="WorkTopic StrangePage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">“Strange Trails” Lyric Publication</p>
        
        <p className="TopicText">
        For this project, we were simply told to create a publication passion project about some sort of media or interest we wanted to take a deeper dive into.
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        I have a fascination with music and enjoy analyzing the lyrics of songs or albums that resonate with me. Inspired by the design of CD cases and inserts—particularly how they display track lists and often include a booklet with song lyrics—I decided to create a publication focused on a concept album. I chose Lord Huron’s album Strange Trails because of its distinct aura and rich storytelling. I created a visually compelling lyric book for this album, driven by imagery representative of characters and settings that emerged as I delved into the lyrics. Strange Trails explores themes of love, loss, longing, adventure, mystery, and the passage of time. To reflect the album's juxtaposition of light and dark themes, I selected imagery that feels eerie yet representative of different narrative perspectives and folklore. The combination of a wispy script typeface with a simple, legible sans serif was inspired by the contrast between the album’s whimsical and serious tones.
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
        * PUBLICATION<br />
        * USING ADOBE PHOTOSHOP, ADOBE INDESIGN
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../strange/Lyric1.png" />
        <Picture img="../strange/Lyric2.png" />
        <Picture img="../strange/Lyric3.png" />
        <Picture img="../strange/Lyric4.png" />
        <Picture img="../strange/Lyric5.png" />
        <Picture img="../strange/Lyric6.png" />
        <Picture img="../strange/Lyric7.png" />
        <Picture img="../strange/Lyric8.png" />
        <Picture img="../strange/Lyric9.png" />
        <Picture img="../strange/Lyric10.png" />
        <Picture img="../strange/Lyric11.png" />
        <Picture img="../strange/Lyric12.png" />
        <Picture img="../strange/Lyric13.png" />
        <Picture img="../strange/Lyric14.png" />
        <Picture img="../strange/Lyric15.png" />
      </div>
    </div>
  )
}