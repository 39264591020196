
import { Picture } from "../Picture"

export default function Nuance() {
  return (
    <div className="WorkTopic NuancePage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">“Nuance” Manifesto Book + Poster</p>
        
        <p className="TopicText">
        The goal of this project was to write a manifesto on a chosen topic and visually represent it on a large wheat paste poster, designed to draw people in for a closer look. Alongside the poster, a publication was created as a handheld version of the manifesto, offering a more personal way to engage with the content. Both the poster and publication were designed to complement each other, ensuring a cohesive visual connection between the two.
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        I decided to research the benefits of looking at life through a nuanced lens, and how this often fosters a more empathetic and human perspective on our experiences. From there I wrote and edited several versions of my manifesto centered around this idea that nothing in life is either black or white. I then found graphics from different fields of study, especially mathematics, that could visually represent different lessons in the manifesto. By completing the poster first, I had created a tool kit of sorts, that aided me in creating the esoteric compositions of my publication’. My main goal was to keep graphics and type similar, and keep the theme of gradients, but slow down the pace of the manifesto to allow the reader to digest the writing. I emphasized leading the reader’s eye from left to right, prompting the reader to turn the pages. What was on each page was made not to be repetitive or expected by the reader. 
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
        * PUBLICATION<br />
        * USING ADOBE PHOTOSHOP, ADOBE ILLUSTRATOR, ADOBE INDESIGN
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../nuance/NuancePG1.png" />
        <Picture img="../nuance/NuancePG2.png" />
        <Picture img="../nuance/BookPG3.jpg" />
        <Picture img="../nuance/BookPG4.JPG" />
        <Picture img="../nuance/BookPG5.JPG" />
        <Picture img="../nuance/BookPG6.JPG" />
        <div className="WorkTopic-Images-Pair">
          <Picture img="../nuance/BookPG7 1 of 2.JPG" />
          <Picture img="../nuance/BookPG7 2 of 2.JPG" />
        </div>
        <Picture img="../nuance/BookPG8.JPG" />
        <div className="WorkTopic-Images-Pair">
          <Picture img="../nuance/BookPG9 1 of 2.png" />
          <Picture img="../nuance/BookPG9 2 of 2.png" />
        </div>
        <Picture img="../nuance/BookPG9.JPG" />
        <Picture img="../nuance/ADD_Nuance.png" />
        <Picture img="../nuance/BookPG10.png" />
      </div>
    </div>
  )
}