import { createContext, useContext } from "react";

const AppContext = createContext({
  setPage: () => {},
  currentPage: 0,
  workCloudVisible: true,
  setWorkCloudVisible: () => {},
});

export { AppContext };

export default function useAppContext() {
  return useContext(AppContext);
}