// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.WorkTopic {
  display: flex;
  flex-direction: row;
  /* gap: 1.2rem; */
  gap: 5rem;
  padding-top: 3rem;
  margin-left: var(--hBodyPadding);
  margin-right: var(--hBodyPadding);
}

.WorkTopic-Text {
  flex-basis: 100%;
  text-align: left;
  color: black;
  font-family: "hepta-slab", sans-serif;
  max-width: 32rem;
  /* position:sticky;
  top: -8rem; */
  height:fit-content;
}

.WorkTopic-Images {
  flex-basis: 100%;
  font-size: 0;
  max-width: 50%;
  .ImgWrapper {
    .ImgWrapper-Img {
      width:100%;
    }
  }
}

.TopicTitle,
.TopicSubtitle,
.TopicHeading {
  font-family: "archivo", sans-serif;  
}

.WorkTopic-Images-Pair {
  display: flex;
  .ImgWrapper{
    max-width: 50%;
  }
}

.TopicTitle {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
}

.TopicSubtitle {
  text-transform: uppercase;
  font-size: 1rem;
}

.TopicHeading {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.TopicText {
  margin-bottom: 2rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
}

`, "",{"version":3,"sources":["webpack://./src/styles/WorkTopic.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,SAAS;EACT,iBAAiB;EACjB,gCAAgC;EAChC,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,qCAAqC;EACrC,gBAAgB;EAChB;eACa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd;IACE;MACE,UAAU;IACZ;EACF;AACF;;AAEA;;;EAGE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb;IACE,cAAc;EAChB;AACF;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["\n\n.WorkTopic {\n  display: flex;\n  flex-direction: row;\n  /* gap: 1.2rem; */\n  gap: 5rem;\n  padding-top: 3rem;\n  margin-left: var(--hBodyPadding);\n  margin-right: var(--hBodyPadding);\n}\n\n.WorkTopic-Text {\n  flex-basis: 100%;\n  text-align: left;\n  color: black;\n  font-family: \"hepta-slab\", sans-serif;\n  max-width: 32rem;\n  /* position:sticky;\n  top: -8rem; */\n  height:fit-content;\n}\n\n.WorkTopic-Images {\n  flex-basis: 100%;\n  font-size: 0;\n  max-width: 50%;\n  .ImgWrapper {\n    .ImgWrapper-Img {\n      width:100%;\n    }\n  }\n}\n\n.TopicTitle,\n.TopicSubtitle,\n.TopicHeading {\n  font-family: \"archivo\", sans-serif;  \n}\n\n.WorkTopic-Images-Pair {\n  display: flex;\n  .ImgWrapper{\n    max-width: 50%;\n  }\n}\n\n.TopicTitle {\n  margin: 0;\n  text-transform: uppercase;\n  font-size: 1.5rem;\n  font-weight: bold;\n}\n\n.TopicSubtitle {\n  text-transform: uppercase;\n  font-size: 1rem;\n}\n\n.TopicHeading {\n  font-size: 1.2rem;\n  margin-bottom: 0;\n}\n.TopicText {\n  margin-bottom: 2rem;\n  line-height: 1.5rem;\n  font-size: 0.8rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
