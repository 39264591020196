
import { Picture } from "../Picture"

export default function Vote() {

  return (
    <div className="WorkTopic VotePage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">"Go Vote" energetic advocacy campaign</p>
        
        <p className="TopicText">
        The goal was to design a voter campaign that is fresh, bright, and playful to engage younger voters—an age group with historically low turnout yet significant potential to influence elections. Drawing inspiration from the historical importance of vote pins and "I Voted" stickers as symbols of civic engagement, the project aims to create visually compelling designs that promote voting in a modern and appealing way.
        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        For this project, I designed cohesive sets of risograph-printed vote pins, complete with custom-designed backers to display them. The pins feature quick reads with witty, modern takes on voter advocacy. I then expanded the campaign with post-voting "I Voted" stickers and voter-encouragement posters, focusing on designs that are vibrant and reward participation, while emphasizing the importance of local or national elections. This playful approach, combined with bold colors and cohesive packaging, is tailored to resonate with younger voters, encouraging higher turnout and a more engaged electorate.
        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
          * COLLAGE
          <br />
          * USING ADOBE PHOTOSHOP
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../vote/vote_01.png" />
        <Picture img="../vote/vote_02.png" />
      </div>
    </div>
  )
}