export default function LadderProject1() {
  return (
    <svg width="119" height="141" viewBox="0 0 119 141" fill="none" xmlns="http://www.w3.org/2000/svg" className="LadderProject1">
      <path d="M1 139.88L73.48 1" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M45.6309 139.88L117.321 1" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M63.0293 21.4902H107.209" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M54.3496 37.6406H98.5296" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M45.9297 53.7598H90.1097" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M37.6309 69.8398H81.8109" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M29.3008 86H73.4808" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M21.1504 102.09H65.3304" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M12.3604 118.26H56.5403" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  )
}