// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/About.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/Contact.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/index.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/NavBar.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/Text.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/Work.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/WorkTopic.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* distances */
  --xSmall: 1.2rem;
  --small: 2rem;
  --medium: 4rem;
  --large: 8rem;

  --hBodyPadding: var(--xSmall);

  --bgColor: #d1c8c1;
  --navBarHeight: 6rem;
  --bodyOffset: calc(var(--navBarHeight) + 5px);

}

html {
  scrollbar-gutter: stable both-edges;
  background-color: var(--bgColor);
}

.App {
  text-align: center;  
}

.App-Page {
  background-color: var(--bgColor);
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: var(--bodyOffset);
  margin-left: var(--hBodyPadding);
  margin-right: var(--hBodyPadding);
}

.row-flex {
  display: flex;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAQA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,cAAc;EACd,aAAa;;EAEb,6BAA6B;;EAE7B,kBAAkB;EAClB,oBAAoB;EACpB,6CAA6C;;AAE/C;;AAEA;EACE,mCAAmC;EACnC,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,8BAA8B;EAC9B,gCAAgC;EAChC,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import \"./styles/About.css\";\n@import \"./styles/Contact.css\";\n@import \"./styles/index.css\";\n@import \"./styles/NavBar.css\";\n@import \"./styles/Text.css\";\n@import \"./styles/Work.css\";\n@import \"./styles/WorkTopic.css\";\n\n:root {\n  /* distances */\n  --xSmall: 1.2rem;\n  --small: 2rem;\n  --medium: 4rem;\n  --large: 8rem;\n\n  --hBodyPadding: var(--xSmall);\n\n  --bgColor: #d1c8c1;\n  --navBarHeight: 6rem;\n  --bodyOffset: calc(var(--navBarHeight) + 5px);\n\n}\n\nhtml {\n  scrollbar-gutter: stable both-edges;\n  background-color: var(--bgColor);\n}\n\n.App {\n  text-align: center;  \n}\n\n.App-Page {\n  background-color: var(--bgColor);\n  /* min-height: 100vh; */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  padding-top: var(--bodyOffset);\n  margin-left: var(--hBodyPadding);\n  margin-right: var(--hBodyPadding);\n}\n\n.row-flex {\n  display: flex;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
