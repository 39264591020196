export default function ShootingStarProject3() {
  return (
    <svg width="108" height="88" viewBox="0 0 108 88" fill="none" xmlns="http://www.w3.org/2000/svg" className="ShootingStarProject3">
      <path d="M12.6213 19.5357C12.6213 19.5357 54.1507 23.8774 87.792 60.8061C87.792 60.8061 50.4648 5.05521 1.05534 1.79492" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M51.7755 12.1016C51.7755 12.1016 80.6745 29.2936 87.792 60.8071" stroke="#FF0000" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M94.6797 86.9595L93.4961 62.1973" stroke="#FF0000" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M102.758 65.0371L85.4168 84.1297" stroke="#FF0000" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M103.251 82.9133L84.925 66.2461" stroke="#FF0000" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M106.96 73.9668L81.2146 75.1914" stroke="#FF0000" strokeWidth="3" strokeMiterlimit="10"/>
    </svg>
  )
}