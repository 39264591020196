import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Contact } from './components/Contact';
import { Work } from './components/Work';
import { About } from './components/About';
import Flux from './components/topics/Flux';
import Pump from './components/topics/Pump';
import Flipturn from './components/topics/Flipturn';
import Exactly from './components/topics/Exactly';
import Strange from './components/topics/Strange';
import Nuance from './components/topics/Nuance';
import Vote from './components/topics/Vote';

const root = ReactDOM.createRoot(document.getElementById('root'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <React.Fragment>
      <ScrollToTop />
        <Routes>
        
        <Route path="/" element={<App />}>
          <Route index element={<Work />} > 

          </Route>

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work/flux" element={< Flux />} />
          <Route path="/work/pump" element={< Pump />} />
          <Route path="/work/flipturn" element={<Flipturn />} />
          <Route path="/work/exactly" element={<Exactly />} />
          <Route path="/work/strange" element={<Strange />} />
          <Route path="/work/nuance" element={<Nuance />} />
          <Route path="/work/vote" element={<Vote />} />
        </Route>
      </Routes>
      </React.Fragment>
    </BrowserRouter>
  </React.StrictMode>
);



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
