
import { Picture } from "../Picture"

export default function Bears() {
  return (
    <div className="WorkTopic BearsPage">
      <div className="WorkTopic-Text">

        <p className="TopicTitle">National Park Symbol Redesign</p>
        
        <p className="TopicText">
        The objective of this project was to redesign a set of national park symbols, particularly those with low comprehension scores, to improve clarity, recognizability, and universal understanding. The challenge involved analyzing comprehension evaluation data, identifying symbols with poor interpretation rates or potentially dangerous misreadings, and applying design strategies to improve semantic closeness, simplicity, and meaning while maintaining visual consistency across the system.

        </p>
        <p className="TopicHeading">PROCESS</p>
        <p className="TopicText">
        I began by examining the low-performing symbols and noting the inconsistencies and missing elements that made them harder to comprehend at a glance. From there, I broke down each term into its core components and identified objects or forms that felt the most universal—essentially building “visual sentences” through simple, intentional shape combinations. Since many of the terms were quite specific, it was important to avoid confusion with similar icons, especially within the context of a national park. I started with quick sketches, then moved into Illustrator to digitize, duplicate, and refine a range of variations until I landed on the clearest reads. To test their effectiveness, I informally surveyed peers outside the design space to gather unbiased, first-read interpretations. I wrapped up by fine-tuning details like stroke weight, visual softness, scale, stylization, and the balance of negative and positive space—ensuring the final icons felt cohesive, approachable, and effective as a complete set.


        </p>
        <p className="TopicHeading">DETAILS</p>
        <p className="TopicText">
        * ICONOGRAPHY <br />
        * USING ADOBE ILLUSTRATOR
        </p>
      </div>
      <div className="WorkTopic-Images">
        <Picture img="../bears/page1.png" />
      </div>
    </div>
  )
}