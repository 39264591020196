import useWorkVisible from "../../utils/useWorkVisible";
import { useRef } from "react";
export default function Brainstorm() {
  
  const element = useRef(null);
  useWorkVisible(element);

  return (
    <button className="Brainstorm-Wrapper" ref={element} onClick={() => {
      const el = document.querySelector(".JNCMain-Subtitle");
      window.scrollTo({top:el.offsetTop, behavior:"smooth"})
    }}>

      <svg /*width="152" height="184" */viewBox="0 0 152 184" fill="none" xmlns="http://www.w3.org/2000/svg" className="Brainstorm">
        
        <path d="M123.609 30.5032C161.361 36.5938 149.098 76.7057 149.098 76.7057C148.198 79.3166 146.915 81.3231 146.387 82H5.26353C1.8527 78.0363 1.00001 71.1899 1 66.6857C2.36433 36.4175 26.3042 36.425 38.3168 38.861C38.3168 38.861 28.1234 4.47746 65.6599 1.19071C103.196 -2.09605 98.1187 38.1902 98.1187 38.1902C106.624 29.1214 117.046 29.4434 123.609 30.5032Z" stroke="#FF0000" strokeWidth="2.5" strokeMiterlimit="10"/>
        <text className="Brainstorm-Text" dominantBaseline="middle" x="50%" y="34%" width="1rem">WORK</text>
        <path d="M16.5 96V107M126.5 96V117.5M88.5 109V120M69 138.5V159.5M29.5 163.5V174.5M97.5 173V184" className="Brainstorm-Path Brainstorm-Path1" />
        <path d="M43.3064 107H40.7041V128.902H43.3064V107Z" fill="#FF0000"/>
        <path d="M53 116.65H31V119.241H53V116.65Z" fill="#FF0000"/>
        <path d="M35.1457 109.288L33.3057 111.12L48.862 126.607L50.7021 124.775L35.1457 109.288Z" fill="#FF0000"/>
        <path d="M48.8542 109.288L33.2979 124.775L35.1379 126.607L50.6943 111.12L48.8542 109.288Z" fill="#FF0000"/>
        <path d="M121.485 142H118.634V166H121.485V142Z" fill="#FF0000"/>
        <path d="M132.108 152.574H108V155.413H132.108V152.574Z" fill="#FF0000"/>
        <path d="M112.543 144.508L110.526 146.516L127.573 163.486L129.589 161.479L112.543 144.508Z" fill="#FF0000"/>
        <path d="M127.565 144.508L110.519 161.479L112.535 163.486L129.581 146.515L127.565 144.508Z" fill="#FF0000"/>
        {/* <path d="M18 96H15V107H18V96Z" className=" Brainstorm-Path Brainstorm-Path1" />
        <path d="M127.602 96H125V117.902H127.602V96Z" className=" Brainstorm-Path Brainstorm-Path2" />
        <path d="M43.3064 107H40.7041V128.902H43.3064V107Z" className=" Brainstorm-Path Brainstorm-Path3" />
        <path d="M53 116.65H31V119.241H53V116.65Z"  className=" Brainstorm-Path Brainstorm-Path4" />
        <path d="M35.1457 109.288L33.3057 111.12L48.862 126.607L50.7021 124.775L35.1457 109.288Z" className="Brainstorm-Path" />
        <path d="M48.8542 109.288L33.2979 124.775L35.1379 126.607L50.6943 111.12L48.8542 109.288Z" className="Brainstorm-Path" />
        <path d="M90 109H87V120H90V109Z"  className=" Brainstorm-Path Brainstorm-Path5" />
        <path d="M70.6023 138H68V159.902H70.6023V138Z" className=" Brainstorm-Path Brainstorm-Path6" />
        <path d="M121.485 142H118.634V166H121.485V142Z"  className=" Brainstorm-Path Brainstorm-Path7" />
        <path d="M132.108 152.574H108V155.413H132.108V152.574Z" className=" Brainstorm-Path Brainstorm-Path8" />
        <path d="M112.543 144.508L110.526 146.516L127.573 163.486L129.589 161.479L112.543 144.508Z" className="Brainstorm-Path" />
        <path d="M127.565 144.508L110.519 161.479L112.535 163.486L129.581 146.515L127.565 144.508Z" className="Brainstorm-Path" />
        <path d="M31 163H28V174H31V163Z" className=" Brainstorm-Path Brainstorm-Path9" />
        <path d="M99 173H96V184H99V173Z" className=" Brainstorm-Path Brainstorm-Path10" />
         */}
      </svg>
    </button>
  )
}
